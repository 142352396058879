import React, { useEffect } from 'react';
import { Text, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Textarea, Button, UnorderedList, ListItem, Box, useToast } from '@chakra-ui/react';
import { patch } from '../../services/api';
import useFormValidation from '../../hooks/useFormValidation';

const EditTechIndicatorModal = ({ isOpen, onClose, reportId, techIndicatorId, strengthSummary, improvementsNeeded, refreshData }) => {
    const toast = useToast();
    const { values, errors, handleChange, handleBlur, isFormValid, isFormChanged, setErrors, setInitialFormValues } = useFormValidation({
        strengthSummary: Array.isArray(strengthSummary)
          ? strengthSummary.join('\n')
          : strengthSummary,
        improvementsNeeded: Array.isArray(improvementsNeeded)
          ? improvementsNeeded.join('\n')
          : improvementsNeeded
    })

    const originalStrengthCount = Array.isArray(strengthSummary) ? strengthSummary.length : 0;
    const originalImprovementCount = Array.isArray(improvementsNeeded) ? improvementsNeeded.length : 0;

    const handleSubmit = async () => {
        if (!isFormChanged()) return;

        const strengthArray = values.strengthSummary && values.strengthSummary.trim()
            ? values.strengthSummary
                .split('\n')
                .map(item => item.replace(/^•\s*/, '').trim())
                .filter(item => item !== '')
            : [];
    
        const improvementsArray = values.improvementsNeeded && values.improvementsNeeded.trim()
            ? values.improvementsNeeded
                .split('\n')
                .map(item => item.replace(/^•\s*/, '').trim())
                .filter(item => item !== '')
            : [];

        const maxStrengthCount = Math.max(originalStrengthCount, strengthArray.length);

        const strengthSummaryIndex = strengthArray.length > 0
            ? Array.from({ length: Math.max(maxStrengthCount, strengthArray.length) }, (_, idx) => ({
                index: idx,
                points: strengthArray[idx] !== undefined ? strengthArray[idx] : null,
                }))
            : [];
            
        const maxImprovementCount = Math.max(originalImprovementCount, improvementsArray.length);
        
        const improvementNeededIndex = improvementsArray.length > 0
            ? Array.from({ length: Math.max(maxImprovementCount, improvementsArray.length) }, (_, idx) => ({
                index: idx,
                points: improvementsArray[idx] !== undefined ? improvementsArray[idx] : null,
                }))
            : [];
          
        const data = {
            strengthSummary: strengthArray,
            improvementsNeeded:improvementsArray,
            strengthSummaryIndex:strengthSummaryIndex,
            improvementNeededIndex:improvementNeededIndex
        };

        console.log(data)

        try {
            const response = await patch(`report/techindicator/${reportId}/${techIndicatorId}`, data);

            toast({
                title: response.data.message,
                description: 'Assertion is updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            refreshData()
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            if(error.response.status === 400)
            {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }
            else if(error.response.status === 404){
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
            }

        }
    };

    // useEffect(() => {
    //     setInitialFormValues({
    //       strengthSummary: Array.isArray(strengthSummary)
    //         ? strengthSummary.join('\n')
    //         : strengthSummary,
    //       improvementsNeeded: Array.isArray(improvementsNeeded)
    //         ? improvementsNeeded.join('\n')
    //         : improvementsNeeded,
    //     });
    //   }, [strengthSummary, improvementsNeeded]);

    useEffect(() => {
        setInitialFormValues({
          strengthSummary: Array.isArray(strengthSummary)
            ? strengthSummary.map(item => `• ${item}`).join('\n')
            : strengthSummary,
          improvementsNeeded: Array.isArray(improvementsNeeded)
            ? improvementsNeeded.map(item => `• ${item}`).join('\n')
            : improvementsNeeded,
        });
    }, [strengthSummary, improvementsNeeded]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={4} p={4}>
                <ModalBody>
                    {strengthSummary.length > 0 &&
                        <Box mb="5">
                            <Text fontWeight="medium" fontSize='14px' mb="2">
                                Strength Summary
                            </Text>
                            <Textarea
                                px={3} height="150px"
                                border='1px solid #F3F4F6'
                                focusBorderColor={errors.strengthSummary ? 'red.500' : '#2970FF'}
                                bg="#F5F8FF" size='md' resize='none'
                                fontSize="14px"
                                borderRadius='10px'
                                placeholder='Strength Summary'
                                value={values.strengthSummary}
                                lineHeight='21px'
                                minHeight='fit-content'
                                onChange={(e) => handleChange('strengthSummary',e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.placeholder === 'Strength Summary') {
                                        e.preventDefault();
                                        const newValue = values.strengthSummary + "\n• ";
                                        handleChange('strengthSummary', newValue);
                                    }
                                }}
                                borderColor={errors.strengthSummary ? 'red.500' : '#D1E0FF'}
                                sx={{
                                    '::placeholder': {
                                    color: '#9DA4AE',
                                    },
                                }}
                                css={{
                                    '::-webkit-scrollbar': { display: 'none' },
                                    '-ms-overflow-style': 'none',
                                    'scrollbar-width': 'none'
                                }}
                            />
                            {errors.strengthSummary && <Text color="red.500" fontSize="sm">{errors.strengthSummary}</Text>}
                        </Box>
                    }
                    {improvementsNeeded.length > 0 &&
                        <Box mb="5" >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Text fontWeight="medium" fontSize='14px' mb="2">
                                    Improvements Needed
                                </Text>
                            </Box>
                            <Textarea
                                height="150px"
                                px={3} bg='#F5F8FF'
                                border='1px solid #F3F4F6'
                                borderRadius='10px'
                                fontSize="14px"
                                focusBorderColor={errors.improvementsNeeded ? 'red.500' : '#2970FF'}
                                placeholder='Improvements Needed'
                                size='md' resize='none'
                                value={values.improvementsNeeded}
                                lineHeight='21px'
                                minHeight='fit-content'
                                textAlign="justify"
                                onChange={(e) => handleChange('improvementsNeeded',e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.placeholder === 'Improvements Needed') {
                                        e.preventDefault();
                                        const newValue = values.improvementsNeeded + "\n• ";
                                        handleChange('improvementsNeeded', newValue);
                                    }
                                }}
                                borderColor={errors.improvementsNeeded ? 'red.500' : '#D1E0FF'}
                                sx={{
                                    '::placeholder': {
                                        color: '#9DA4AE',
                                    },
                                }}
                                css={{
                                    '::-webkit-scrollbar': { display: 'none' },
                                    '-ms-overflow-style': 'none',
                                    'scrollbar-width': 'none'
                                }}
                            />
                            {errors.improvementsNeeded && <Text color="red.500" fontSize="sm">{errors.improvementsNeeded}</Text>}
                        </Box>
                    }
                </ModalBody>
                <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                    <Button variant='ghost' bg='#EFF4FF' _hover={{ bg: '#EFF4FF' }} color='#2970FF' width='47.5%' onClick={onClose}>Cancel</Button>
                    <Button
                        bg={isFormChanged() ? "#2970FF" : "#F04438"}
                        color='white'
                        variant='solid'
                        width='47.5%'
                        _hover={{ bg: isFormChanged() ? '#7044c4' : '#F04438' }}
                        onClick={handleSubmit}
                        isDisabled={!isFormChanged()}
                    >
                        Save and Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditTechIndicatorModal;